import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import * as Models from '../../models/signin/Signin';
//import Utility from '../../default/Utility'
import * as SigninModels from '../../models/signin/Signin';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/Signin';

type Store = Models.SigninState & typeof Stores.actionCreators;

class GoogleButtonComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: string, data: SigninModels.SignInData }, Store > {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isLoginSuccess: "",
            data: {
                social_id: "",
                first_name: "",
                last_name: "",
                email: ""
            }
        };
        this.responseGoogle = this.responseGoogle.bind(this);
    }

    responseGoogle(response: any) {

        console.log(response);

        if (response.googleId != undefined) {
            var profileData = response.profileObj;
            var data = this.state.data;
            console.log(response);
            data.social_id = profileData.googleId;
            data.email = profileData.email;
            data.first_name = profileData.givenName;
            data.last_name = profileData.familyName;

        this.setState({
            data: {
                social_id: profileData.googleId,
                first_name: profileData.givenName,
                last_name: profileData.familyName,
                email: profileData.email
            }
        });

            console.log(this.state.data);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.props.requestFunction("", "", "google", this.state.data);
        }

    }

    render() {
        //let { isLoading } = this.state;
        let { loginObject } = this.props;

        return (
            <GoogleLogin
                clientId={loginObject.socialAuth.google_client_id}
                render={renderProps => (
                    <button type="button" onClick={renderProps.onClick} disabled={renderProps.disabled} className="mt-3 btn btn-light btn-block"><img style={{ paddingRight: "15px" }} src={require('../../images/icon/icon-google.png')} />
                        <span><b>Login with Google</b></span>
                    </button>
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
        );
    }
}

export default connect(
    (state: ApplicationState) => state.signin,
    Stores.actionCreators
)(GoogleButtonComponent);
