import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/SignInAZAY';
import * as Models from '../../models/signin/SignInAZAY';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'

type Store = Models.SigninState & typeof Stores.actionCreators;

class LoginButtonAZAYComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: string, redirect: boolean }, Store> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            isLoginSuccess: "",
            redirect: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate() {
        let { isLoading } = this.state;
        let { mode, endpoint } = this.props.loginObject;
        console.log(this.state)
        console.log(this.props)
        if (this.props.isLoginSuccess && isLoading) {
            console.log(this.props.responseSignin)
            if (this.props.responseSignin != undefined && this.props.responseSignin.token != "" && this.props.responseSignin.token != undefined && this.props.responseSignin.token != null) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                if (endpoint) {
                    console.log(localStorage.getItem('SSO-WEB_APPLICATION'))
                    var path = endpoint + "?token=" + localStorage.getItem('SSO-WEB_APPLICATION');

                    if (mode) {
                        path += '&mode=' + mode;
                    }

                    return (window.top as Window).location.href = path;
                } else {
                    this.setState({ redirect: true })
                }
            }
            else {
                //this.props.requestFunction('', '')
                //this.setState({ isLoading: false })
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                Swal.fire({
                    title: 'Username & Password Invalid!',
                    text: 'Username และ Password ไม่ถูกต้อง',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {
                    this.setState({ isLoading: false })
                })
            }
        }
    }

    private onSubmit() {
        const { username, password } = this.props.loginObject;
        if (username != "" && password != "") {
            this.setState({
                isLoading: true
            })
            this.props.requestFunction(username, password);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'Required Username & Password!',
                text: 'กรุณากรอก Username และ Password ให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    render() {
        let { isLoading, redirect } = this.state;

        if (redirect) {
            return <Redirect to='/' />
        }

        return (
            <button type="submit" className="btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }} onClick={this.onSubmit} disabled={isLoading}>
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {isLoading && <span>Loading Data from Server</span>}
                {!isLoading && <span><b>Login</b></span>}
            </button>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.signInAZAY,
    Stores.actionCreators
)(LoginButtonAZAYComponent);
