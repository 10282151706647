import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AzureButtonComponent from './AzureButtonComponent';
import queryString from 'query-string';
import './style/Signin.css';

import * as AppStore from '../../store/AppState';
import * as SocialModel from '../../models/AppState';
import { ApplicationState } from '../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as RedirectStore from '../../store/signin/Redirect';

import { FaUserLock } from 'react-icons/fa';
import colors from '../../default/Colors';
import LoginButtonPoolComponent from './LoginButtonPoolComponent';
import LoginButtonPoolWithAzureComponent from './LoginButtonPoolWithAzureComponent';
import { parseJwt } from '../Common/Common';
import imgFile from '../../images/F1 ERP_Logo.png';
import { ResponseSignIn } from '../../models/signin/Signin';
import OtpComponent from '../auth/TwoFactor/OtpComponent';
import { showLoading, hideLoading } from '../Common/Common';
import axios from 'axios';
import { OtpResponse } from '../../models/signin/OtpResponse';
import Swal from 'sweetalert2';

import { UserAgentApplication } from 'msal';
//import * as msal from "@azure/msal-browser";
import { getUserDetail, getUserProfile } from './azure/utils/MSUtils';
import { SignInData } from '../../models/signin/Signin';
import * as SignInStore from '../../store/signin/Signin';
import AllowPopupModal from './AllowPopupModal';
import AllowPopupConnectPreparationModal from './AllowPopupConnectPreparationModal';
import AllowConnectOnboardModal from './AllowConnectOnboardModal';

const ignoreError = ['User cancelled the flow.'];

const popupBlockMessage = 'Popups are blocked in the browser';
const accountIsNotSupportMessage = 'AADSTS650051';
const loginInProgressMessage = 'Login_In_Progress';
const replaceErrorWord = [
    [
        'AADSTS650051',
        'Your selected work or school or personal account is not support'
    ],
    [
        'Login_In_Progress: Error during login call - login is already in progress.',
        'Login is already in progress'
    ],
    [
        'Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser.',
        'Pop-up is blocked in the browser'
    ]
];

function SignInComponentV2() {
    const location = useLocation();
    const dispatch = useDispatch();

    const { appState, Redirect } = useSelector<
        Defined<ApplicationState>,
        Defined<Pick<ApplicationState, 'appState' | 'Redirect'>>
    >(state => ({
        appState: state.appState,
        Redirect: state.Redirect
    }));

    const [token, setToken] = useState('');
    const [mode, setMode] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [version, setVersion] = useState('');
    const [useMFA, setUseMFA] = useState('false');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirectRequest, setRedirectRequest] = useState<{
        redirectPath: string | undefined;
        loginType: string | undefined;
    }>({
        redirectPath: undefined,
        loginType: undefined
    });
    const [socialAuth, setSocialAuth] = useState<SocialModel.SocialAuth>();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [confirmedOtp, setConfirmedOtp] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otpResponse, setOtpResponse] = useState<OtpResponse>();
    const azureButtonComponentRef = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = () => setIsOpen(!isOpen);
    const [openOnboardModal, setOpenOnboardModal] = useState(false);
    // const onToggleOnboardModal = () => {
    //     setIsOpen(!isOpen);
    //     setOpenOnboardModal(!openOnboardModal);
    // }

    // Check if key `latest-otp-response` is in local storage and is not expired
    // If yes, then show OTP modal
    useEffect(() => {
        const latestOtpResponseJson = localStorage.getItem(
            'latest-otp-response'
        );
        if (!latestOtpResponseJson) {
            return;
        }

        const latestOtpResponse = JSON.parse(
            latestOtpResponseJson
        ) as OtpResponse;
        const expirationDate = new Date(latestOtpResponse.expiration);

        if (expirationDate < new Date()) {
            localStorage.removeItem('latest-otp-response');
            return;
        }

        setOtpResponse(latestOtpResponse);
        setShowOtpModal(true);
    }, []);

    useEffect(() => {
        if (redirectRequest.redirectPath) {
            if (redirectRequest.loginType === ''
                && useMFA === 'true') {
                hideLoading();
                const otp = async () => {
                    await getOtp();
                    setShowOtpModal(true);
                };
                otp().catch(e => {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด!',
                        text: 'ขอ OTP ไม่สำเร็จกรุณาเข้าสู่ระบบอีกครั้ง',
                        titleText: 'ขอ OTP ไม่สำเร็จ',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        allowOutsideClick: false
                    });
                });

                return;
            }

            setConfirmedOtp(true);
        }
    }, [redirectRequest]);

    useEffect(() => {
        if (confirmedOtp) {
            showLoading();

            // Clear nescesary values in local storage
            ['token', 'mode', 'redirect', 'otp-response'].forEach(key =>
                localStorage.removeItem(`latest-${key}`)
            );

            (window.top as Window).location.href =
                redirectRequest.redirectPath!;
        }
    }, [confirmedOtp]);

    useEffect(() => {
        const params = queryString.parse(location.search) as Record<
            string,
            string | undefined
        >;

        // Save query params to local storage
        ['token', 'mode', 'redirect'].forEach(key => {
            const value = params[key];

            if (value) {
                localStorage.setItem(`latest-${key}`, value);
            } else {
                params[key] =
                    localStorage.getItem(`latest-${key}`) ?? undefined;
            }
        });

        const { token, mode, redirect } = params;

        fetchVersion();
        fetchUseMFA();
        dispatch(AppStore.actionCreators.requestAppInit());

        if (redirect) {
            dispatch(
                RedirectStore.actionCreators.requestFunctionRedirect(
                    redirect as string,
                    'SET'
                )
            );
        }

        if (!token) {
            setMode(mode as string);

            return;
        }

        // Do all of these when token is not null
        const authenticated = parseJwt(token as string);
        if (!authenticated.extra) {
            return;
        }

        const userInformation = JSON.parse(authenticated.extra);
        if (!userInformation.callback_id) {
            return;
        }

        fetchCallbackId(userInformation.callback_id);
        setMode(mode as string);
        setToken(token as string);
    }, []);

    const [data, setData] = useState<SignInData | undefined>(undefined);

    const getUserAgentApplication = () => {
        if (socialAuth) {
            return new UserAgentApplication({
                auth: {
                    authority: socialAuth.azure_authority_uri,
                    clientId: socialAuth.azure_client_id as string,
                    redirectUri: socialAuth.azure_login_redirect_uri,
                    postLogoutRedirectUri:
                        socialAuth.azure_logout_redirect_uri
                },
                cache: {
                    //cacheLocation: "sessionStorage", // needed to avoid "login required" error
                    cacheLocation: 'localStorage', // needed to avoid "login required" error
                    storeAuthStateInCookie: true // recommended to avoid certain IE/Edge issues
                }
            });
        } else {
            return undefined
        }

    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            //console.log(hash)

            if (socialAuth) {
                var userAgentApplication = getUserAgentApplication();
                if (userAgentApplication) {
                    console.log(userAgentApplication.getAllAccounts());

                    const fetchData = async () => {
                        try {
                            userAgentApplication?.handleRedirectCallback(
                                async (_, response) => {
                                    //console.log(response)
                                    if (response) {
                                        const accountObj = response.account;
                                        //console.log(accountObj)

                                        if (accountObj) {
                                            var azureData = {
                                                email: accountObj.userName,
                                                social_id:
                                                    accountObj.accountIdentifier,
                                                first_name: accountObj.name,
                                                last_name: ''
                                            };

                                            setData(azureData);

                                            return;
                                        } else {
                                            //console.log(hash)
                                            const azureAccessToken = hash.substring(
                                                hash.indexOf(
                                                    '&error_description='
                                                ) + 19,
                                                hash.indexOf('&state')
                                            );
                                            var errMsg =
                                                decodeURIComponent(
                                                    azureAccessToken
                                                );
                                            if (errMsg) {
                                                errMsg = errMsg.replace(/\+/g, ' ');
                                            }

                                            //console.log(errMsg);
                                            await azureErrorHandle(errMsg);
                                        }
                                    }
                                }
                            );
                        } catch (err) {
                            await Swal.fire({
                                title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
                                text: (err as Error).message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                cancelButtonColor: '#d33'
                            }).then(result => {
                                if (result.isConfirmed) {
                                    //localStorage.removeItem('SSO-WEB_LOGIN_TYPE');
                                    //window.open('/auth/signoutAzure', '', 'height=600,width=483');
                                }
                            });
                        }
                    };

                    fetchData()
                        // make sure to catch any error
                        .catch(console.error);
                }
            }
        }
    }, [socialAuth]);

    //useEffect(() => {
    //    azureErrorHandle(popupBlockMessage);
    //},[])

    function isFromMobileBrowser() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const fetchUseMFA = async () => {
        const data = await (await fetch('/v1/use-mfa')).json();

        setUseMFA(data.message);
    };

    const azureErrorHandle = async (message: string = '') => {
        const isMobile = isFromMobileBrowser();

        await axios.post('v1/log', {
            function: isMobile ? 'login_with_azure_mobile' : 'login_with_azure',
            message: message,
            created_by: 'system',
            created_on: '',
            level: 'ERROR',
            ref_code: 'rfc3339'
        });

        const shoudlIgnore = ignoreError.some(v => v === message);
        if (shoudlIgnore) {
            // await Swal.fire({
            //    title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
            //    text: message,
            //    icon: 'error',
            //    showCancelButton: true,
            //    confirmButtonColor: '#3085d6',
            //    confirmButtonText: 'Sign Out Azure AD',
            //    cancelButtonColor: "#d33",
            // }).then(result => {
            //    if (result.isConfirmed) {
            //        localStorage.removeItem('SSO-WEB_LOGIN_TYPE');
            //        window.open('/auth/signoutAzure', '', 'height=600,width=483');
            //    }
            // });

            return;
        }

        let replaceMsg = message;
        // var replaceMsg = popupBlockMessage; //Test Modal Allow Popup
        if (
            replaceMsg.toLowerCase().includes(popupBlockMessage.toLowerCase())
        ) {
            const result = await Swal.fire({
                title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
                text: 'Pop-up is blocked in the browser',
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: getUserAgentApplication() ? true : false,
                confirmButtonColor: '#29529E',
                denyButtonColor: '#F75710',
                //showDenyButton: true,
                confirmButtonText: `Redirect to Login with Azure`,
                denyButtonText: 'How to allow pop-up block',
            });
            if (result.isConfirmed) {
                var userAgentApplication = getUserAgentApplication();
                if (userAgentApplication) {
                    //var userAgentApplication = getUserAgentApplication();
                    var scopes = ['user.read'];

                    await userAgentApplication?.loginRedirect({
                        scopes: scopes,
                        prompt: 'select_account'
                    });
                }
            } else if (result.isDenied) {
                setIsOpen(true);
            }

            return;
        }

        
        if (
            replaceMsg.toLowerCase().includes(accountIsNotSupportMessage.toLowerCase())
        ) {
            const result = await Swal.fire({
                title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
                text: 'Your selected work or school or personal account is not support, Try again with another account',
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: '#29529E',
                //showDenyButton: true,
                confirmButtonText: `Try Login with Azure again`,
            });
            if (result.isConfirmed) {
                var userAgentApplication = getUserAgentApplication();
                if (userAgentApplication) {
                    //var userAgentApplication = getUserAgentApplication();
                    var scopes = ['user.read'];
                    const isMobile = isFromMobileBrowser();

                    try {
                        showLoading();

                        if (isMobile) {
                            await userAgentApplication.loginRedirect({
                                scopes: scopes,
                                prompt: 'select_account'
                            });

                            return;
                        } else {
                            //console.log(userAgentApplication)
                            await userAgentApplication.loginPopup({
                                scopes: scopes,
                                prompt: 'select_account'
                            });

                            var account = userAgentApplication.getAccount()
                            var azureData = {
                                email: account.userName,
                                social_id: account.accountIdentifier,
                                first_name: account.name,
                                last_name: ''
                            };

                            //console.log(userAgentApplication)
                            setData(azureData);

                            return;
                        }

                        //const user = await getUserProfile(userAgentApplication, scopes);
                        //console.log(user)

                        //var azureData = {
                        //    email: user.mail || user.userPrincipalName,
                        //    social_id: user.id,
                        //    first_name: user.givenName,
                        //    last_name: user.surname
                        //};

                        //console.log(user)
                    } catch (err) {
                        setData(undefined);

                        hideLoading();

                        //console.log((err as Error).message);
                        const errMessage = (err as Error).message;

                        await azureErrorHandle(errMessage);

                    } finally {
                        hideLoading();
                    }
                }
            } 

            return;
        }

        if (
            replaceMsg.toLowerCase().includes(loginInProgressMessage.toLowerCase())
        ) {
            const result = await Swal.fire({
                title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
                text: 'Login is already in progress, please close all opened login pages and try Login with Azure again',
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: '#29529E',
                //showDenyButton: true,
                confirmButtonText: `Try Login with Azure again`,
            });
            if (result.isConfirmed) {
                var userAgentApplication = getUserAgentApplication();
                if (userAgentApplication) {
                    //var userAgentApplication = getUserAgentApplication();
                    var scopes = ['user.read'];
                    const isMobile = isFromMobileBrowser();

                    try {
                        showLoading();

                        if (isMobile) {
                            await userAgentApplication.loginRedirect({
                                scopes: scopes,
                                prompt: 'select_account'
                            });

                            return;
                        } else {
                            //console.log(userAgentApplication)
                            await userAgentApplication.loginPopup({
                                scopes: scopes,
                                prompt: 'select_account'
                            });

                            var account = userAgentApplication.getAccount()
                            var azureData = {
                                email: account.userName,
                                social_id: account.accountIdentifier,
                                first_name: account.name,
                                last_name: ''
                            };

                            //console.log(userAgentApplication)
                            setData(azureData);

                            return;
                        }

                        //const user = await getUserProfile(userAgentApplication, scopes);
                        //console.log(user)

                        //var azureData = {
                        //    email: user.mail || user.userPrincipalName,
                        //    social_id: user.id,
                        //    first_name: user.givenName,
                        //    last_name: user.surname
                        //};

                        //console.log(user)
                    } catch (err) {
                        setData(undefined);

                        hideLoading();

                        //console.log((err as Error).message);
                        const errMessage = (err as Error).message;

                        await azureErrorHandle(errMessage);

                    } finally {
                        hideLoading();
                    }
                }
            }

            return;
        }

        //replaceErrorWord.some(([key, value]) => {
        //    if (message.includes(key)) {
        //        replaceMsg = value;

        //        return true;
        //    } else {
        //        return false;
        //    }
        //});

        await Swal.fire({
            title: 'เกิดข้อผิดพลาดจากระบบ Azure AD',
            text: replaceMsg,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33'
        }).then(result => {
            if (result.isConfirmed) {
                //localStorage.removeItem('SSO-WEB_LOGIN_TYPE');
                //window.open('/auth/signoutAzure', '_blank', 'noopener,noreferrer');
            }
        });
    };

    //useEffect(() => {
    //    if (data) {
    //        //console.log(data)
    //        dispatch(
    //            SignInStore.actionCreators.requestFunction(
    //                'POST',
    //                '',
    //                '',
    //                'azure',
    //                data
    //            )
    //        );
    //    }
    //}, [data]);

    useEffect(() => {
        const { isLoaded, socialAuth } = appState;
        if (!isLoaded) {
            return;
        }

        setSocialAuth(socialAuth);
    }, [appState]);

    const getOtp = async (type: string = '') => {
        showLoading();

        try {
            const res = (
                await axios.post<OtpResponse>('/v1/get-otp', null, {
                    params: {
                        type
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'SSO-WEB_APPLICATION'
                        )}`
                    }
                })
            ).data;

            const expirationDate = new Date();
            expirationDate.setMinutes(expirationDate.getMinutes() + 2);
            res.expiration = expirationDate;

            localStorage.setItem('latest-otp-response', JSON.stringify(res));
            setOtpResponse(res);
            hideLoading();
        } catch (e) {
            hideLoading();

            //const result = (e as Error).message;
            //if (result)
            //{
            //    var msg = JSON.parse(result)

            //    if (msg.message === 'REQUEST_OTP_FAILED') {
            //Swal.fire({
            //    title: 'เกิดข้อผิดพลาด!',
            //    text: "ขอ OTP ไม่สำเร็จกรุณาเข้าสู่ระบบอีกครั้ง",
            //    titleText: 'ขอ OTP ไม่สำเร็จ',
            //    icon: 'error',
            //    showCancelButton: false,
            //    confirmButtonColor: '#3085d6',
            //    allowOutsideClick: false
            //})
            //    }
            //    else
            //    {

            //    }
            //}
            throw e;
        }
    };

    const loginSuccessCallback = (accessToken: string, type: string) => {
        showLoading();
        //console.log('azureLoginSuccessCallback accessToken', accessToken);
        //console.log('azureLoginSuccessCallback type', type);

        if (endpoint) {
            //console.log(localStorage.getItem('SSO-WEB_APPLICATION'))
            //var path = endpoint + "?token=" + localStorage.getItem('SSO-WEB_APPLICATION');
            var path = endpoint + '?token=' + accessToken;

            if (mode) {
                path += '&mode=' + mode;
            }

            //if (this.props.redirect != "" && this.props.redirect != undefined) {
            //    path += '&redirect=' + this.props.redirect
            //}
            if (Redirect) {
                path += '&redirect=' + Redirect.redirect;
            }

            //return (window.top as Window).location.href = path;
            //setRedirectPath(path);
            setRedirectRequest({
                redirectPath: path,
                loginType: type
            });
        } else {
            //setRedirect(true);
            //setRedirectPath('/');
            setRedirectRequest({
                redirectPath: '/',
                loginType: type
            });
        }
    };

    const fetchCallbackId = async (callbackId: string) => {
        const response = await fetch(
            '/v1/citizen/auth/callback?id=' + callbackId
        );
        const data = await response.json();

        setEndpoint(data.endpoint);
    };

    const fetchVersion = async () => {
        const data = await (await fetch('/v1/version')).json();

        setVersion(data.message);
    };

    const handleChangeUsername = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();

            setUsername(event.target.value);
        },
        []
    );

    const handleChangePassword = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();

            setPassword(event.target.value);
        },
        []
    );

    const confirmOtp = () => {
        setConfirmedOtp(true);
    };

    const toggleShowLoginModal = useCallback(() => {
        setShowLoginModal(prevState => !prevState);
    }, [setShowLoginModal]);

    const toggleShowOtpModal = useCallback(() => {
        setShowOtpModal(prevState => !prevState);
    }, [setShowOtpModal]);

    const onReSendOtp = async () => {
        const data = (
            await axios.post<OtpResponse>('/v1/get-otp', null, {
                params: {
                    type: 'resend'
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'SSO-WEB_APPLICATION'
                    )}`
                }
            })
        ).data;

        // If user can select otp options
        if (!data.refKey) {
            const result = await Swal.fire({
                title: 'Option!',
                text: 'ส่งรหัส OTP ใหม่ไปที่',
                input: 'radio',
                inputOptions: {
                    email: 'Email',
                    line: 'Line'
                },
                inputValue: otpResponse?.to.includes('@') ? 'email' : 'line',
                icon: 'info',
                confirmButtonText: 'Resend!',
                showCloseButton: true,
                allowOutsideClick: false
            });

            if (!result.isConfirmed) {
                return;
            }

            await getOtp(result.value);
        } else {
            await getOtp();
        }
    };

    const onClickLoginWithAzureAD = () => {
        azureButtonComponentRef.current?.click();
    };

    return (
        <div>
            <form
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <div className="col-lg-4 col-md-6 col-sm-12 signin-font">
                    <div className="div-logo">
                        <img src={imgFile} className="logo-login" alt="file" />
                    </div>
                    <br />
                    <br />
                    {/*<h3>Sign In</h3>
                        <div className="form-group">
                        <label style={{ fontFamily: "sans-serif" }}>Username</label>
                        <input type="Text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
                    </div> */}
                    {/* <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={(e) => this.rememberMe(e)} />
                                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-end">
                                <Link to="/forgotpassword">
                                    <a>Forgot Password</a>
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    {/* <LoginButtonComponent loginObject={loginObject} history={this.props.history} />
                    <LoginButtonF1Component loginObject={loginObject} />
                    <LoginButtonPoolComponent loginObject={loginObject} /> */}

                    {socialAuth && (
                        <div>
                            {/*{socialAuth.facebook_app_id &&
                                <div>
                                    <FacebookButtonComponent loginObject={loginObject} />
                                </div>
                            }
                            {socialAuth.google_client_id &&
                                <div>
                                    <GoogleButtonComponent loginObject={loginObject} />
                                </div>
                            }*/}
                            {socialAuth.azure_client_id && (
                                <div>
                                    <AzureButtonComponent
                                        ref={azureButtonComponentRef}
                                        azureInitData={data}
                                        loginObject={socialAuth}
                                        loginSuccessCallback={
                                            loginSuccessCallback
                                        }
                                        onClickLoginWithAzureAD={
                                            onClickLoginWithAzureAD
                                        }
                                        azureErrorHandle={azureErrorHandle}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <div
                        className="hr-sect"
                        style={{
                            fontFamily: 'sans-serif',
                            marginBottom: '20.5px'
                        }}
                    >
                        OR
                    </div>

                    <button
                        type="button"
                        onClick={toggleShowLoginModal}
                        className="btn btn-info btn-block"
                        style={{
                            backgroundColor: colors.btnSSO_Orange,
                            borderColor: colors.btnSSO_Orange
                        }}
                    >
                        <FaUserLock
                            style={{
                                width: '1.5em',
                                height: '1.5em',
                                marginRight: '10px'
                            }}
                        />
                        <span>
                            <b>&nbsp;Login With Local</b>
                        </span>
                    </button>

                    {/*<p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p>*/}

                    {/* <FacebookButtonComponent loginObject={loginObject} />

                        <GoogleButtonComponent loginObject={loginObject} /> 

                        <AzureButtonComponent loginObject={loginObject} /> */}

                    {/*<Link to="/citizen/register">
                        <button className="mt-3 btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }}><b>Register</b></button>
                    </Link>*/}

                    <br />
                    <br />

                    <span
                        style={{
                            fontSize: '8pt',
                            paddingLeft: '5px',
                            float: 'right'
                        }}
                    >
                        Version {version}
                    </span>

                    {/* <button type="button" className="mt-3 btn btn-info btn-block" onClick={this.testCallback}><b>Test Callback</b></button> */}
                </div>
            </form>

            <Modal
                backdrop="static"
                isOpen={showLoginModal}
                size="xl"
                centered={true}
            >
                <ModalHeader
                    style={{ borderBottom: '0px', paddingBottom: '0px' }}
                    toggle={toggleShowLoginModal}
                ></ModalHeader>
                <ModalBody className="pt-0">
                    <form>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '60vh'
                            }}
                        >
                            <div className="col-lg-6 col-md-8 col-sm-12 signin-font">
                                <div className="div-logo">
                                    <img
                                        src={imgFile}
                                        className="logo-login"
                                        alt="file"
                                    />
                                </div>
                                {/*<h3>Sign In</h3>*/}

                                <div className="form-group">
                                    <label style={{ fontFamily: 'sans-serif' }}>
                                        Username
                                    </label>
                                    <input
                                        type="Text"
                                        className="form-control"
                                        placeholder="Enter username"
                                        name="username"
                                        value={username}
                                        onChange={handleChangeUsername}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter password"
                                        name="password"
                                        value={password}
                                        onChange={handleChangePassword}
                                    />
                                </div>

                                {/*<LoginButtonPoolComponent loginObject={{ mode, endpoint, username, password }} history={history} />*/}
                                <LoginButtonPoolWithAzureComponent
                                    data={undefined}
                                    grandParentLoginObject={{
                                        username,
                                        password
                                    }}
                                    loginSuccessCallback={loginSuccessCallback}
                                    onClickLoginWithAzureAD={
                                        onClickLoginWithAzureAD
                                    }
                                />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter style={{ borderTop: '0px' }}></ModalFooter>
            </Modal>

            <OtpComponent
                show={showOtpModal}
                otpResponse={otpResponse!}
                onReSendOtp={onReSendOtp}
                close={toggleShowOtpModal}
                confirmOtp={confirmOtp}
            />

            {/* <AllowPopupModal isOpen={isOpen} onToggle={onToggle} /> */}
            <AllowPopupConnectPreparationModal
                isOpen={isOpen}
                onToggle={onToggle}
                onClickStartConnect={() => {
                    setIsOpen(!isOpen);
                    setOpenOnboardModal(!openOnboardModal);
                }}
            />
            <AllowConnectOnboardModal
                openOnboardModal={openOnboardModal}
                onClose={() => {
                    setOpenOnboardModal(!openOnboardModal);
                }}

            />
        </div>
    );
}

export default SignInComponentV2;

//export default SignInComponent
