import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import { Spinner } from 'reactstrap';
import Loader from 'react-loader-advanced';
import './custom.css'
import f1Loading from './assets/gifs/f1_loading.gif'

const loadImage = <div>
                    <img
                        src={f1Loading}
                        alt="loading-gif"
                        className="img-loader"
                    />
                </div>;

export default () => (
    <Loader show={true} message={loadImage}>
        <Layout>
            {  /*<Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />*/}
        </Layout>
    </Loader>
);
