import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useWindowSize } from 'usehooks-ts';
// import { twMerge } from 'tailwind-merge';
import { ImArrowDown, ImArrowRight } from 'react-icons/im';

import { Step1, Step2, Step3 } from './AllowOnboarding';
import './style/AllowPopup.css';

type Props = {
    isOpen?: boolean;
    onToggle?: () => void;
    onClickStartConnect?: () => void;
};

function AllowPopupConnectPreparationModal(props: Props) {
    const { isOpen, onToggle, onClickStartConnect } = props;

    const { width } = useWindowSize();
    const sm = 640;

    const stepContainerTwClasses =
        'relative flex flex-col border border-neutral-200 rounded-md p-4';
    const stepBaseTwClasses =
        'absolute top-[calc(100%_+_2px)] left-1/2 -translate-x-1/2 translate-y-0';
    const stepEvenTwClasses =
        'sm:hidden 2xl:block 2xl:top-1/2 2xl:translate-x-0 2xl:-translate-y-1/2 2xl:left-[calc(100%_+_6px)]';
    const stepOddTwClasses =
        'sm:top-1/2 sm:translate-x-0 sm:-translate-y-1/2 sm:left-[calc(100%_+_6px)]';

    const ArrowIcon = width > sm ? ImArrowRight : ImArrowDown;

    return (
        <Modal isOpen={isOpen} toggle={onToggle} size="xl" scrollable>
            <ModalHeader
                toggle={onToggle}
                className="justify-center flex items-center h-screen"
            >
                <div className="mx-auto">
                    <h2 className="header-text">How to allow pop-up block</h2>
                </div>
            </ModalHeader>
            <ModalBody className="grid-container">
                <div className={stepContainerTwClasses}>
                    <Step1 />

                    {/* <ArrowIcon
                            size={32}
                            color="#F7980A"
                            className={twMerge(
                                stepBaseTwClasses,
                                stepOddTwClasses
                            )}
                        /> */}
                </div>
                <div className={stepContainerTwClasses}>
                    <Step2 />
                </div>
                <div className={stepContainerTwClasses}>
                    <Step3 />
                </div>
            </ModalBody>
            <ModalFooter className="justify-center flex items-center h-screen">
                {/*<Button*/}
                {/*    size="sm"*/}
                {/*    color="success"*/}
                {/*    className="border-0 py-2 px-4 mx-auto"*/}
                {/*    onClick={onClickStartConnect}*/}
                {/*>*/}
                {/*    เริ่มต้น Connect!*/}
                {/*</Button>*/}
            </ModalFooter>
        </Modal>
    );
}

export default AllowPopupConnectPreparationModal;
