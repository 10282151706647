import * as React from 'react';
import { Redirect } from "react-router";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/Signin';
import * as Models from '../../models/signin/Signin';
//import Utility from '../../default/Utility'
import { GoogleLogout } from 'react-google-login';
//import { config } from './azure/Config';
import { normalizeError, getUserProfile } from './azure/utils/MSUtils';
import { UserAgentApplication } from 'msal';
import * as EditCitizenModels from '../../models/EditCitizen';
import * as EditCitizenStores from '../../store/EditCitizen';

//export const userAgentApplication = new UserAgentApplication({
//    auth: {
//        clientId: config.clientId,
//        redirectUri: config.redirectUri,
//        postLogoutRedirectUri: config.postLogoutRedirectUri,
//    },
//    cache: {
//        cacheLocation: "sessionStorage",
//        storeAuthStateInCookie: true
//    }
//});

type EditCitizenStore = EditCitizenModels.EditCitizenState & typeof Stores.actionCreators;
type Store = Models.SigninState & typeof Stores.actionCreators.requestFunction;

class SignOut extends React.Component<any, Store & EditCitizenStore> {

    constructor(props: any) {
        super(props);
    }

    public render() {
        var type: any = localStorage.getItem("SSO-WEB_LOGIN_TYPE");
        if (type == "azure") {
            localStorage.removeItem('SSO-WEB_LOGIN_TYPE');
            return <Redirect to="/auth/signoutAzure" />
        } else {
            this.props.requestFunction('', '');
            this.props.requestFunctionGet('', '', '');

            localStorage.removeItem('SSO-WEB_APPLICATION');
            localStorage.removeItem('SSO-WEB_LOGIN_TYPE');
        }
        return (
                <>
                <Redirect to="/auth/signin" />
                </>
            )
    }
}

export default connect(
    (state: ApplicationState) =>
        ({ ...state.signin, ...state.editCitizen, }),
    ({ ...EditCitizenStores.actionCreators, ...Stores.actionCreators, }))
(SignOut);