const tryParseJson = <T>(text: string) => {
    try {
        return JSON.parse(text) as T;
    } catch {
        return void 0;
    }
}

export default {
    tryParseJson
}