import React from 'react';
function Step3() {
    return (
        <React.Fragment>
            <div>
                <h4 className="box-header">Computer</h4>
            </div>
            <br />
            <div>
                <div className="text-underline">
                    <h6>Chrome</h6>
                </div>
                <ol>
                    <li>
                        On your computer, open <b> Chrome.</b>
                    </li>
                    <li>
                        At the top right, click More
                        <b> : Setting.</b>
                    </li>
                    <li>
                        Click Privacy and security
                        <b> : Site Settings </b>
                        <b> : Pop-ups and redirects.</b>.
                    </li>
                    <li>
                        Choose the option that you want as your default setting.
                    </li>
                </ol>
            </div>
            <hr />
            <div>
                <div className="text-underline">
                    <h6>Safari</h6>
                </div>
                <ol>
                    <li>Open a Safari browser window.</li>
                    <li>
                        Select the Safari menu
                        <b>: Preferences from the drop down menu.</b>
                    </li>
                    <li>
                        select <b> Security </b> on the top row.
                    </li>
                    <li>
                        <b> Uncheck </b> the checkbox Block pop-up windows.
                    </li>
                    <li>Close the Preferences window.</li>
                </ol>
            </div>
            <hr />
            <div className="text-underline">
                <h6>Edge</h6>
            </div>
            <ol>
                <li>In Edge, go to Settings and more</li>
                <li>
                    Select Settings
                    <b> : Cookies and site permissions.</b>
                </li>
                <li>Under All permissions, select Pop-ups and redirects.</li>
                <li>
                    Turn on the <b>Block (recommended) </b>toggle.
                </li>
            </ol>
            <hr />
        </React.Fragment>
    );
}

export default Step3;
