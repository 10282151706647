import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import FacebookButtonComponent from './FacebookButtonComponent';
import GoogleButtonComponent from './GoogleButtonComponent';
import AzureButtonComponent from './AzureButtonComponent';
import queryString from 'query-string';
import './style/Signin.css';

import * as AppStore from '../../store/AppState';
import * as SocialModel from '../../models/AppState';
import { ApplicationState } from '../../store';
import LoginButtonComponent from './LoginButtonComponent';
import {
    Link,
    RouteComponentProps,
    useHistory,
    useLocation
} from 'react-router-dom';
import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';
import Swal from 'sweetalert2';
import colors from '../../default/Colors';
import LoginButtonPoolComponent from './LoginButtonPoolComponent';

var imgFile = require('../../images/F1 ERP_Logo.png');

function SignInComponent() {
    const location = useLocation();

    const dispatch = useDispatch();
    const { appState, Redirect } = useSelector<
        Defined<ApplicationState>,
        Defined<Pick<ApplicationState, 'appState' | 'Redirect'>>
    >(state => ({
        appState: state.appState,
        Redirect: state.Redirect
    }));
    const { isLoaded, socialAuth } = appState;

    const [token, setToken] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [mode, setMode] = useState('');

    const [version, setVersion] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const params = queryString.parse(location.search) as Record<
            string,
            string | undefined
        >;
        const {
            token,
            mode,
            redirect = 'https://apps.thailanderp.com/login'
        } = params;

        // Save query params to local storage
        ['token', 'mode', 'redirect'].forEach(key => {
            const value = params[key];

            if (value) {
                localStorage.setItem(`latest-${key}`, value);
            } else {
                params[key] =
                    localStorage.getItem(`latest-${key}`) ?? undefined;
            }
        });

        console.log(redirect);

        fetchVersion();
        dispatch(AppStore.actionCreators.requestAppInit());

        if (token != null) {
            var jwt = parseJwt(token);
            if (jwt.extra != undefined) {
                var extra = JSON.parse(jwt.extra);
                var callbackId = extra.callback_id;
                if (callbackId != undefined) {
                    console.log(callbackId);
                    fetchCallback(callbackId);
                }
            }
        } else {
            setMode(mode || '');
        }
    }, []);

    useEffect(() => {
        const { redirect = 'https://apps.thailanderp.com/login' } =
            queryString.parse(location.search) as Record<string, string>;

        dispatch(
            RedirectStore.actionCreators.requestFunctionRedirect(
                redirect,
                'SET'
            )
        );
    }, []);

    const fetchVersion = async () => {
        const response = await fetch('/v1/version');
        const data = await response.json();

        setVersion(data.message);
    };

    const fetchCallback = async (callbackId: string) => {
        const response = await fetch(
            '/v1/citizen/auth/callback?id=' + callbackId
        );
        try {
            const data = await response.json();

            setToken(data.token);
            setEndpoint(data.endpoint);
            setMode(data.mode);
        } catch (error) {
            Swal.fire({
                title: 'ติดต่อผู้ดูแลระบบ!',
                text: 'ไม่มีการกำหนด CallBack ของระบบ กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            });
        }
    };

    const parseJwt = (token: string) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    };

    const onChangeUsername = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
        },
        []
    );

    const onChangePassword = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        },
        []
    );

    if (!isLoaded) {
        return null;
    }

    return (
        <form>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <div className="col-lg-4 col-md-6 col-sm-12 signin-font">
                    <div className="div-logo">
                        <img src={imgFile} className="logo-login" alt="file" />
                    </div>
                    <h3>Sign In</h3>

                    <div className="form-group">
                        <label style={{ fontFamily: 'sans-serif' }}>
                            Username
                        </label>
                        <input
                            type="Text"
                            className="form-control"
                            placeholder="Enter username"
                            name="username"
                            onChange={onChangeUsername}
                        />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            name="password"
                            onChange={onChangePassword}
                        />
                    </div>
                    <div className="row">
                        {/*<div className="col-lg-6">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <div className="custom-control custom-checkbox">*/}
                        {/*            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={(e) => this.rememberMe(e)} />*/}
                        {/*            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div
                            className="col-lg-6"
                            style={{ maxWidth: '100%', WebkitFlex: '0 0 100%' }}
                        >
                            <div className="d-flex justify-content-end">
                                <Link to="/forgotpassword">
                                    <a>Forgot Password</a>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <LoginButtonComponent
                        loginObject={{
                            username,
                            password,
                            endpoint,
                            mode
                        }}
                        history={useHistory()}
                    />
                    {/* <LoginButtonF1Component loginObject={loginObject} /> */}
                    {/*<LoginButtonPoolComponent loginObject={loginObject} />*/}

                    {/*<p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p>*/}
                    {/* <FacebookButtonComponent loginObject={loginObject} />

                        <GoogleButtonComponent loginObject={loginObject} /> 

                        <AzureButtonComponent loginObject={loginObject} /> */}

                    {/*<Link to="/citizen/register">*/}
                    {/*<button className="mt-3 btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }}><b>Register</b></button>*/}
                    {/*</Link>*/}
                    <div>
                        <div
                            className="d-flex justify-content-center"
                            style={{ marginTop: 30, marginBottom: 5 }}
                        >
                            Don't have an account yet? &nbsp;
                            <Link
                                to={{
                                    pathname: '/citizen/register',
                                    state: {
                                        previousSearch: location.search
                                    }
                                }}
                            >
                                <a>Register</a>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div
                            style={{
                                borderBottom: 1,
                                borderBottomStyle: 'solid',
                                borderBottomColor: 'grey',
                                width: '75%'
                            }}
                        ></div>
                    </div>
                    <div>
                        <div
                            className="d-flex justify-content-center"
                            style={{ marginTop: 5 }}
                        >
                            <p>How to Register &nbsp;</p>
                            <a
                                target="_blank"
                                href="https://f1-help.thailanderp.com/Help/HelpSubmodule.aspx?cid=00044&hcode=00501&hid=41fc28c4-e60c-4b09-9094-cee3e31e9a72&xlevel=0"
                            >
                                Here
                            </a>
                        </div>
                    </div>

                    {socialAuth && (
                        <div className="signin-font">
                            {/*<div className="d-flex justify-content-center">*/}
                            {/*    Sign in with*/}
                            {/*</div>*/}
                            {socialAuth.facebook_app_id && (
                                <div>
                                    <FacebookButtonComponent
                                        loginObject={{ socialAuth }}
                                    />
                                </div>
                            )}
                            {socialAuth.google_client_id && (
                                <div>
                                    <GoogleButtonComponent
                                        loginObject={{ socialAuth }}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <span
                        style={{
                            fontSize: '8pt',
                            paddingLeft: '5px',
                            float: 'right',
                            paddingTop: '20px'
                        }}
                    >
                        Version {version}
                    </span>

                    {/* <button type="button" className="mt-3 btn btn-info btn-block" onClick={this.testCallback}><b>Test Callback</b></button> */}
                </div>
            </div>
        </form>
    );
}

export default SignInComponent;
