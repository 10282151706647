import React, { useCallback, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import LoginButtonPoolWithAzureComponent from '../LoginButtonPoolWithAzureComponent';
import f1ERPLogo from '../../../images/F1 ERP_Logo.png';
import { SignInData } from '../../../models/signin/Signin';

type Props = {
    show: boolean;
    data: SignInData | undefined;
    close: () => void;
    loginSuccessCallback: (token: string, type: string) => void;
    onClickLoginWithAzureAD: () => void;
};

function AzureMappingUserComponent(props: Props) {
    const {
        show,
        close,
        data,
        loginSuccessCallback,
        onClickLoginWithAzureAD
    } = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeUsername = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setUsername(event.target.value);
    }, []);

    const handleChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setPassword(event.target.value);
    }, []);

    return (
        <Modal
            isOpen={show}
            backdrop="static"
            className="my-4 mx-auto"
            size="xl"
            style={{ maxWidth: '90vw' }}
            toggle={close}
            centered={true}
        >
            <ModalHeader style={{ borderBottom: '0px', paddingBottom: '0px' }} toggle={close}>
            </ModalHeader>
            <ModalBody style={{ paddingTop: '0px' }}>
                {/*<form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>*/}
                {/*    <div className="col-lg-6 col-md-8 col-sm-12 signin-font">*/}
                {/*        <div className="div-logo">*/}
                {/*            <img src={f1ERPLogo} className="logo-login" alt="file" />*/}
                {/*        </div>*/}

                {/*        */}{/*<h4 className="text-center">Sign In</h4>*/}

                {/*        <div className="form-group">*/}
                {/*            <label style={{ fontFamily: "sans-serif" }}>Username</label>*/}
                {/*            <input type="Text" className="form-control" placeholder="Enter username" name="username" onChange={handleChangeUsername} />*/}
                {/*        </div>*/}

                {/*        <div className="form-group">*/}
                {/*            <label>Password</label>*/}
                {/*            <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={handleChangePassword} />*/}
                {/*        </div>*/}

                {/*        <LoginButtonPoolWithAzureComponent*/}
                {/*            data={data}*/}
                {/*            grandParentLoginObject={{ username, password }}*/}
                {/*            loginSuccessCallback={loginSuccessCallback}*/}
                {/*            onClickLoginWithAzureAD={onClickLoginWithAzureAD}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</form>*/}
                <div className="border-top d-flex pt-3">
                    {/*<p style={{ flex: 1 }} className="text-center">เนื่องจากเป็นการ Login ด้วย Azure AD ด้วย Account : <span style={{ fontWeight: 500 }}>{data?.email}</span> เป็นครั้งแรก กรุณาผูกเข้ากับบัญชีเดิมในระบบ</p>*/}
                    <p style={{ flex: 1 }} className="text-center">เนื่องจากการ Login ผ่าน Azure AD ด้วย Account : <span style={{ fontWeight: 500 }}>{data?.email}</span> ไม่พบการผูกบัญชีเข้ากับระบบ focusOne กรุณาติดต่อผู้ดูแลระบบ</p>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AzureMappingUserComponent;