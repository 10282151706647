import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import Swal from 'sweetalert2';

import { showLoading, hideLoading } from '../../Common/Common';
import { OtpResponse } from '../../../models/signin/OtpResponse';
import { useCountdownTimer } from '../../../common/hooks';

type Props = {
    show: boolean;
    //loginObject: any;
    otpResponse: OtpResponse;
    onReSendOtp: () => void;
    close: () => void;
    confirmOtp: () => void;
};

function OtpComponent(props: Props) {
    const {
        show,
        close,
        otpResponse,
        onReSendOtp,
        //loginObject
        confirmOtp
    } = props;

    const diffSeconds = useMemo(
        () =>
            Math.floor(
                (new Date(otpResponse?.expiration).getTime() -
                    new Date().getTime()) /
                    1000
            ) || 0,
        [otpResponse]
    );

    const [otp, setOtp] = useState('');
    const { isCounting, remainingTime, startCountdown, stopCounting } =
        useCountdownTimer({
            initialSeconds: 120
        });

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            try {
                showLoading();
                await verifyOtp();
            } catch {
                hideLoading();
                await Swal.fire({
                    title: 'OTP',
                    text: 'ยืนยัน OTP ผิดพลาดโปรดลองใหม่อีกครั้ง',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง!'
                });
            } finally {
                hideLoading();
            }
        },
        [otp]
    );

    const verifyOtp = async () => {
        const response = await axios.get('/v1/verify-otp', {
            params: {
                id: otpResponse?.id,
                otp: otp,
                ref_key: otpResponse?.refKey
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    'SSO-WEB_APPLICATION'
                )}`
            }
        });

        if (response.status !== 200) {
            throw new Error('OTP ผิดพลาด');
        }

        confirmOtp();
    };

    useEffect(() => {
        stopCounting();
        startCountdown(diffSeconds || 120);
    }, [otpResponse]);

    const handleClose = () => {
        close();
        localStorage.removeItem('latest-otp-response');
    };

    return (
        <Modal
            centered
            isOpen={show}
            backdrop="static"
            size="xl"
            style={{ maxWidth: '600px' }}
            className="my-4 mx-auto"
        >
            <ModalHeader className="border-0 pb-0" toggle={handleClose} />
            <ModalBody className="pb-4 px-4 pt-0 d-flex flex-column">
                <div className="pb-3">
                    <p
                        className="text-center"
                        style={{ fontWeight: 500, fontSize: '1.125rem' }}
                    >
                        <span className="font-semibold">
                            focus
                            <span
                                style={{ color: '#ffa500' }}
                                className="text-[#ffa500] font-extrabold"
                            >
                                O
                            </span>
                            ne ERP - Single Sign On
                        </span>
                    </p>
                    <div className="mt-3 text-center">
                        <p>
                            <span>
                                ระบบได้ทำการส่ง OTP ไปที่{' '}
                                {otpResponse?.to.includes('@') ? (
                                    <span>
                                        อีเมล์ของคุณ{' '}
                                        <span
                                            style={{
                                                fontWeight: 500,
                                                color: '#0081C9'
                                            }}
                                        >
                                            {otpResponse?.to}
                                        </span>
                                    </span>
                                ) : (
                                    <span style={{ fontWeight: 500 }}>
                                        'Line FocusOne Official Account'
                                    </span>
                                )}
                            </span>
                            {/*ระบบได้ทำการส่ง OTP ไปที่อีเมล์ของคุณ{' '}*/}
                            {/*<span style={{ fontWeight: 500, color: '#0081C9' }}>*/}
                            {/*    {otpResponse?.to}*/}
                            {/*</span>*/}
                        </p>
                        <p className="mt-2">
                            เมื่อได้รับ OTP ให้ระบุเพื่อยืนยันตัวตน
                        </p>
                        <p>
                            เลขที่อ้างอิงการขอ OTP :{' '}
                            <span style={{ fontWeight: 500 }}>
                                {otpResponse?.refKey}
                            </span>
                        </p>
                    </div>
                    <form
                        className="gap-otp"
                        onSubmit={handleSubmit}
                        style={{
                            marginTop: '6px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderInput={props => (
                                <input
                                    {...props}
                                    style={{
                                        marginRight: '8px',
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '4px',
                                        borderWidth: '1px',
                                        textAlign: 'center'
                                    }}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            style={{
                                background: isCounting ? '#269E41' : '',
                                borderRadius: '50px',
                                border: 0,
                                padding: '6px 24px'
                            }}
                            disabled={!isCounting}
                        >
                            Submit
                        </Button>
                    </form>
                </div>
                <div className="border-top d-flex justify-content-between pt-3">
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                        This OTP will expire in {Math.floor(remainingTime / 60)}
                        :{(remainingTime % 60).toString().padStart(2, '0')}{' '}
                        minutes
                    </span>
                    <button
                        style={{
                            border: 'none',
                            background: 'none',
                            color: !isCounting ? '#0081C9' : ''
                        }}
                        disabled={isCounting}
                        onClick={onReSendOtp}
                    >
                        Resend OTP {'>'}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default OtpComponent;
