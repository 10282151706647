import React from 'react';
import { Button } from 'reactstrap';
function Step2() {
    return (
        <React.Fragment>
            <div>
                <h4 className="box-header">Android</h4>
            </div>
            <br />
            <div>
                <div className="text-underline">
                    <h6>Chrome</h6>
                </div>
                <ol>
                    <li>
                        On your Android phone or tablet, open the Chrome app.
                    </li>
                    <li>
                        To the right of the address bar, tap More
                        <b> : Setting.</b>
                    </li>
                    <li>
                        Tap Permissions
                        <b> : Pop-ups and redirects.</b>
                    </li>
                    <li>
                        Turn off <b> Pop-ups and redirects.</b>
                    </li>
                </ol>
                <hr />
            </div>
        </React.Fragment>
    );
}
export default Step2;
