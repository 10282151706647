import * as React from 'react';
import FacebookLogin from 'react-facebook-login';
import { connect } from 'react-redux';
import * as Models from '../../models/signin/Signin';
//import Utility from '../../default/Utility';
import * as SigninModels from '../../models/signin/Signin';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/Signin';

type Store = Models.SigninState & typeof Stores.actionCreators;

class FacebookButtonComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: string, data: SigninModels.SignInData }, Store > {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isLoginSuccess: "",
            data: {
                social_id: "",
                first_name: "",
                last_name: "",
                email: ""
            }
        };
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    responseFacebook = (response: any) => {

        if (response != undefined) {

            var res = response;

            if (res.id != undefined) {

                var name = res.name.split(" ")
                var data = this.state.data;

                data.social_id = res.id;
                data.email = res.email;
                data.first_name = name[0];

                if (name[2] == undefined) {
                    data.last_name = name[1];
                } else {
                    data.last_name = name[1] + " " + name[2];
                }

                this.setState({
                    data: data
                });

                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.props.requestFunction("", "", "facebook", this.state.data);
            }
        }
    }

    render() {
        //let { isLoading } = this.state;
        let { loginObject } = this.props;

        return (
            <FacebookLogin
                appId={loginObject.socialAuth.facebook_app_id}
                fields="name,email,picture"
                textButton="&nbsp;&nbsp;&nbsp;&nbsp;Login with Facebook"
                callback={this.responseFacebook}
                icon="fa-facebook"
                cssClass="btn btn-primary btn-block mx-2"
            />

        );
    }
}

export default connect(
    (state: ApplicationState) => state.signin,
    Stores.actionCreators
)(FacebookButtonComponent);
