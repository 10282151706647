import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import rawPDPAInfo from '../../assets/miscellaneous/PDPA.txt';

type Props = {
    isOpen: boolean;
    onToggle: () => void;
};

function PDPAInfoModal(props: Props) {
    const { isOpen, onToggle } = props;

    const [info, setInfo] = useState('');

    useEffect(() => {
        fetchPDPAInfo();
    }, []);

    const fetchPDPAInfo = useCallback(async () => {
        const response = await fetch('/pdpa')
        const rawText = await response.text();

        setInfo(rawText);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
        >
            <ModalHeader toggle={onToggle} className="font-weight-bold">
                ความยินยอมในการเก็บรวบรวม ใช้ หรือ เปิดเผยข้อมูลส่วนบุคคล
            </ModalHeader>
            <ModalBody>
                <div style={{ whiteSpace: 'pre-line' }}>
                    {info}
                </div>
            </ModalBody>
        </Modal>
    );
}

export default PDPAInfoModal;