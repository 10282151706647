import React from 'react';
import { Button } from 'reactstrap';

function Step1() {
    // const containerStyle = {
    //     display: 'flex',
    //     alignItems: 'baseline',
    //     gap: '0.75rem', // space-x-3 is equivalent to 0.75rem in Tailwind
    //     fontSize: '1.125rem' // text-lg is equivalent to 1.125rem in Tailwind
    // };

    return (
        <React.Fragment>
            <div>
                <h4 className="box-header">iPhone & iPad</h4>
            </div>
            <br />
            <div className="text-underline">
                <h6>Chrome</h6>
            </div>
            <ol>
                <li>On your iPhone or iPad, open the Chrome app</li>
                <li>
                    Tap More <b> Setting.</b>
                </li>
                <li>
                    Tap Content Settings
                    <b> : Block Pop-ups.</b>
                </li>
                <li>
                    Turn <b> Block Pop-ups </b> on or off.
                </li>
            </ol>
            <hr />
            <div>
                <div className="text-underline">
                    <h6>Safari</h6>
                </div>
                <ol>
                    <li>Open the Settings app on your device</li>
                    <li>Select Safari</li>
                    <li>
                        Under General, locate <b> Block Pop-ups </b>
                        and slide the button to the disabled position in order
                        to allow pop-ups.
                    </li>
                </ol>
                <hr />
            </div>
        </React.Fragment>
    );
}
export default Step1;
