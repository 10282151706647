import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Container, Row, Col, Table, InputGroup, FormControl, Form, ButtonGroup } from "react-bootstrap";
import { FaPlusCircle, FaKey, FaTrashAlt, FaRegCopy } from 'react-icons/fa';
import Swal from 'sweetalert2'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ApplicationState } from "../../store";
import * as CallbackConfigMasterStore from '../../store/callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../../models/callbackconfig/CallbackMaster'

import * as CallbackTokenStore from '../../store/callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../../models/callbackconfig/CallbackTokens'

import * as CreateCallbackStore from '../../store/callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../../models/callbackconfig/CreateCallback'
import queryString from "query-string"

type CallbackConfigProps = ModelsCallbackConfigMaster.CallbackMasterState & typeof CallbackConfigMasterStore.actionCreators;
type CallbackTokenProps = ModelsCallbackTokens.CallbackTokenState & typeof CallbackTokenStore.actionCreators;
type CreateCallbackProps = ModelsCreateCallback.SaveCallbackState & typeof CreateCallbackStore.actionCreators;

export interface initRow {
    id: string,
    name: string,
    token: string,
}

class AddConfigMaster extends React.Component<any, { rows: Array<ModelsCallbackConfigMaster.Callback>, checkRequest: boolean, checkResponse: boolean, checkGenerate: boolean, textarea: any, requestrows: Array<ModelsCreateCallback.CallbackReq>, deleterows: Array<ModelsCreateCallback.CallbackReq>, copied: boolean }, CallbackConfigProps & CallbackTokenProps & CreateCallbackProps>{
    //private  textInput = React.useRef<HTMLInputElement>(null);
    constructor(props: any) {
        super(props);
        this.state = {
            rows: [],
            requestrows: [],
            deleterows: [],
            checkRequest: true,
            checkResponse: true,
            checkGenerate: false,
            textarea: "",
            copied: false
        }
    }

    componentDidMount() {
        const { token } = queryString.parse(this.props.location.search);
        if (token) {
            localStorage.setItem('SSO-WEB_APPLICATION', token as string);
        }
        if (this.state.checkRequest) {
            this.props.requestCallbackMaster(true, "POST")
            this.setState({
                checkRequest: false,
            })
        }
    }

    handleAddRow = () => {
        const item = {
            sid: "",
            id: "",
            name: "",
            token: "",
            endpoint: "",
            created_by: "",
            created_on: ""
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
        setTimeout(() => {
            console.log(this.state.rows)
        }, 1000);
    };

    handleRemoveSpecificRow = (idx: number) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
        var deletesub: Array<ModelsCreateCallback.CallbackReq> = [];
        var landingrow = {
            id: this.state.rows[idx].id,
            token: this.state.rows[idx].token,
            name: this.state.rows[idx].name,
            endpoint: this.state.rows[idx].endpoint
        };
        deletesub.push(landingrow);
        this.setState({
            deleterows: deletesub
        })
    }

    handleChange = (idx: number, mode: String) => (e: { target: { name: any; value: any; } }) => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        console.log(mode)
        if (mode == "Token") {
            const item = {
                sid: rows[idx].sid,
                id: rows[idx].id,
                name: rows[idx].name,
                token: value,
                endpoint: rows[idx].endpoint,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else if (mode == "Name") {
            const item = {
                sid: rows[idx].sid,
                id: rows[idx].id,
                name: value,
                token: rows[idx].token,
                endpoint: rows[idx].endpoint,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else if (mode == "Callback") {
            const item = {
                sid: rows[idx].sid,
                id: rows[idx].id,
                name: rows[idx].name,
                token: rows[idx].token,
                endpoint: value,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        }
    }

    onsubmit = () => {
        // console.log(this.state.rows)
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        var rowsub: Array<ModelsCreateCallback.CallbackReq> = [];
        rowsub = this.state.requestrows
        for (var i = 0; i < this.state.rows.length; i++) {
            //rowsub[i].id = this.state.rows[i].id
            //rowsub[i].name = this.state.rows[i].name
            //rowsub[i].token = this.state.rows[i].token
            //rowsub[i].endpoint = this.state.rows[i].endpoint
            var landingrow = {
                id: this.state.rows[i].id,
                token: this.state.rows[i].token,
                name: this.state.rows[i].name,
                endpoint: this.state.rows[i].endpoint
            };
            rowsub.push(landingrow);
        }
        this.setState({
            requestrows: rowsub
        })
        this.props.requestCreateCallback(true, "POST", this.state.requestrows);
    }

    generateToken = (id: any, idx: any) => {
        console.log(id + " : " + idx)
        if (id != undefined && id != null && id != "") {
            this.props.requestCallbackToken(true, "POST", id, idx)
            this.setState({
                checkGenerate: true
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'กรุณาบันทึก Name และ Callback ก่อนจึงจะสามารถ generate token ได้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    //copyCodeToClipboard = () => {
    //    const el = this.textInput
    //    //el.current
    //    document.execCommand("copy")
    //}

    onCopy = () => {
        this.setState({ copied: true });
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Copy success!'
        })
    };

    componentDidUpdate(nextProps: any, nextState: any) {
        const { Callbackstatus, Callbackresponse, CallbackTokenstatus, CallbackTokenresponse, CallbackTokenrequest, SaveCallbackstatus, SaveCallbackresponse, SaveCallbackisLoad, SaveCallbackmessage } = this.props
        if (Callbackstatus == 200 && this.state.checkResponse && Callbackresponse['total_count'] != 0) {
            this.setState({
                checkResponse: false,
                rows: Callbackresponse['result_list'],
            })
        } else if (CallbackTokenstatus == 200 && this.state.checkGenerate) {
            this.setState({
                checkGenerate: false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'generate token สำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    const rows = [...this.state.rows];
                    const item = {
                        sid: rows[CallbackTokenrequest['idx']].sid,
                        id: rows[CallbackTokenrequest['idx']].id,
                        name: rows[CallbackTokenrequest['idx']].name,
                        token: CallbackTokenresponse['token'],
                        endpoint: rows[CallbackTokenrequest['idx']].endpoint,
                        created_by: rows[CallbackTokenrequest['idx']].created_by,
                        created_on: rows[CallbackTokenrequest['idx']].created_on
                    };
                    rows[CallbackTokenrequest['idx']] = item;
                    this.setState({
                        rows
                    });
                    this.props.requestCallbackToken(true, "CLEAR", "", 0)
                }
            })
        } else if (SaveCallbackstatus == 200 && SaveCallbackresponse['total_count'] != 0 && SaveCallbackisLoad) {
            this.setState({
                rows: SaveCallbackresponse['result_list'],
                requestrows: []
            })
            this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    //this.props.requestCallbackMaster(true, "POST")
                    console.log(this.state.requestrows)
                }
            })
        } else if (SaveCallbackstatus == 200 && SaveCallbackresponse['total_count'] == 0 && SaveCallbackisLoad) {
            this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    //this.props.requestCallbackMaster(true, "POST")
                    console.log(this.state.requestrows)
                }
            })
        } else if (SaveCallbackstatus == 204 && SaveCallbackisLoad) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: '' + SaveCallbackmessage + '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestCallbackMaster(true, "POST")
                }
            })
        }
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                            <Card.Header>
                                <Row>
                                    <Col><h4>Config Master</h4></Col>
                                    <Col className='text-right'>
                                        {/* <Link to="/roleemployee">
                                            <Button variant="warning" size="sm"><FaArrowCircleLeft />&nbsp;Back</Button>
                                        </Link> */}
                                        <Button className="mx-2" variant="info" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row className='mt-3'>
                                    <Col>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr className='text-center' style={{ width: '100%' }}>
                                                    <th style={{ width: '5%' }}>#</th>
                                                    <th style={{ width: '20%' }}>Name</th>
                                                    <th style={{ width: '45%' }}>Token</th>
                                                    <th style={{ width: '30%' }}>Callback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.rows.map((item, idx) => (
                                                    <tr id="addr0" key={idx}>
                                                        <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificRow(idx)}><FaTrashAlt /></Button></td>
                                                        <td>
                                                            <InputGroup className="mb-3">
                                                                <FormControl
                                                                    placeholder="username"
                                                                    value={this.state.rows[idx].name}
                                                                    onChange={this.handleChange(idx, 'Name')}
                                                                />
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    readOnly={true}
                                                                    placeholder=""
                                                                    value={this.state.rows[idx].token}
                                                                    onChange={this.handleChange(idx, 'Token')}
                                                                    id="tokenText"
                                                                //ref={this.textInput}
                                                                />
                                                                <InputGroup.Append>
                                                                    <ButtonGroup vertical>
                                                                        <Button variant="info" onClick={() => this.generateToken(item.id, idx)}><FaKey /></Button>
                                                                        <CopyToClipboard onCopy={this.onCopy} text={this.state.rows[idx].token}>
                                                                            <Button variant="primary"><FaRegCopy /></Button>
                                                                        </CopyToClipboard>
                                                                    </ButtonGroup>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                placeholder=""
                                                                value={this.state.rows[idx].endpoint}
                                                                onChange={this.handleChange(idx, 'Callback')}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className='text-center'><Button className="mx-2" variant="info" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.callbackConfig, ...state.callbackTokens, ...state.createCallback }), ({ ...CallbackConfigMasterStore.actionCreators, ...CallbackTokenStore.actionCreators, ...CreateCallbackStore.actionCreators }))(AddConfigMaster)