import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/Signin';
import * as Models from '../../models/signin/Signin';
import * as UserExpireStore from '../../store/signin/UserExpire';
import * as UserExpireModels from '../../models/signin/UserExpire'
import * as ForgotPasswordModels from '../../models/signin/ForgotPassword';
import * as ForgotPasswordStore from '../../store/signin/ForgotPassword';
import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'
import { validation } from '../../common/helpers';

type Store = Models.SigninState & typeof Stores.actionCreators | UserExpireModels.UserExpireState & typeof UserExpireStore.actionCreators | ForgotPasswordModels.GetForgotPasswordState & typeof ForgotPasswordStore.actionCreators | RedirectModels.RedirectState & typeof RedirectStore.actionCreators

class LoginButtonComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: string, typeLogin: any }, Store> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            isLoginSuccess: "",
            typeLogin: ""
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    private onSubmit() {
        const { username, password } = this.props.loginObject;
        if (username != "" && password != "") {
            this.setState({
                isLoading: true
            })
            this.props.requestFunction("POST", username, password, "email", "");
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'Required Username & Password!',
                text: 'กรุณากรอก Username และ Password ให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        let { mode, endpoint, username } = this.props.loginObject;
        const { isLoadUserExpire, responseUserExpire, statusUserExpire, messageUserExpire, isLoadGetForgotPassword, responseGetForgotPassword, statusGetForgotPassword, messageGetForgotPassword } = this.props

        if (localStorage.getItem('SSO-WEB_LOGIN_TYPE') != this.state.typeLogin) {
            this.setState({
                typeLogin: localStorage.getItem('SSO-WEB_LOGIN_TYPE')
            })
        }
        if (this.state.typeLogin == "email") {
            if (this.props.isLoginSuccess) {
                if (this.props.statusLogin == 200) {
                    // Clear nescesary values in local storage
                    ['token', 'mode', 'redirect', 'otp-response'].forEach(key =>
                        localStorage.removeItem(`latest-${key}`)
                    );

                    if (this.props.responseSignin.token != "" && this.props.responseSignin.token != undefined && this.props.responseSignin.token != null) {
                        console.log(endpoint)
                        if (endpoint) {
                            console.log('endpoint already')
                            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                            console.log(localStorage.getItem('SSO-WEB_APPLICATION'))
                            var path = endpoint + "?token=" + localStorage.getItem('SSO-WEB_APPLICATION');

                            if (mode) {
                                path += '&mode=' + mode;
                            }

                            if (this.props.redirect != "" || this.props.redirect != undefined) {
                                path += '&redirect=' + this.props.redirect
                            }
                            //console.log(path)
                            return (window.top as Window).location.href = path;
                        } else {
                            //return <Redirect to='/' />
                            //Check user expire
                            this.props.requestUserExpire(true, "GET");
                            this.props.requestFunction("CLEAR", "", "", "", "");
                        }
                    }
                }
                else if (this.props.statusLogin != 200) {
                    var msg = JSON.parse(this.props.messageSingin)
                    if (msg.message == 'current user is not active') {
                        this.props.requestFunction('', '')
                        this.setState({ isLoading: false })
                        var redirectPath = this.props.redirect
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                        Swal.fire({
                            title: 'Error!',
                            text: msg.message,
                            titleText: 'Re-send email to authentication',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Re-send email',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.props.requestFunction("CLEAR", "", "", "", "");
                                var dataReq: any = {}
                                dataReq['username'] = username // ใช้ username แทน
                                console.log(dataReq)
                                this.props.requestForgotPassword(true, "GET", dataReq, redirectPath);
                                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                            }
                        })
                    } else {
                        this.props.requestFunction('', '')
                        this.setState({ isLoading: false })
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                        Swal.fire({
                            title: 'Error!',
                            text: msg.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.props.requestFunction("CLEAR", "", "", "", "");
                            }
                        })
                    }
                }
            }

            // Check expried user
            if (isLoadUserExpire && statusUserExpire == 200) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                if (responseUserExpire['expirationDateAlert']) {
                    if (responseUserExpire['expirationDate'] > 0) {
                        Swal.fire({
                            title: 'Warning!',
                            text: responseUserExpire.message,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.props.requestUserExpire(true, "CLEAR");
                                this.props.history.push('/')
                            }
                        })
                    } else {
                        var redirectPath = this.props.redirect
                        Swal.fire({
                            title: 'Error!',
                            text: responseUserExpire.message + "\nPlease click 'OK' for re-password",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.props.requestUserExpire(true, "CLEAR");
                                var Reqemail = responseUserExpire['email']
                                var dataReq: any = {}
                                dataReq['email'] = Reqemail
                                console.log(dataReq)
                                this.props.requestForgotPassword(true, "GET", dataReq, redirectPath);
                                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                            }
                        })
                    }
                } else {
                    this.props.requestUserExpire(true, "CLEAR");
                    /*                return <Redirect to='/' />*/
                    this.props.history.push('/')
                }
            }

            //Check forgot password
            if (isLoadGetForgotPassword && statusGetForgotPassword == 200) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                Swal.fire({
                    title: 'Success!',
                    text: 'A request has been sent to your email , Please check email',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.props.requestForgotPassword(true, "CLEAR", '');
                        this.props.history.push('/auth/signin');
                    }
                })
            } else if (isLoadGetForgotPassword && statusGetForgotPassword == 400) {
                const error = validation.tryParseJson<Error>(messageGetForgotPassword);

                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

                Swal.fire({
                    title: 'Error!',
                    text: error ? error.message : messageGetForgotPassword,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.props.requestForgotPassword(true, "CLEAR", '');
                    }
                })
            }
        }
    }

    render() {
        let { isLoading } = this.state;
        return (
            <button type="submit" className="btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }} onClick={this.onSubmit} disabled={isLoading}>
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {isLoading && <span>Loading Data from Server</span>}
                {!isLoading && <span><b>Login</b></span>}
            </button>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.signin, ...state.UserExpire, ...state.ForgotPassword, ...state.Redirect }),
    ({ ...Stores.actionCreators, ...UserExpireStore.actionCreators, ...ForgotPasswordStore.actionCreators, ...RedirectStore.actionCreators })
)(LoginButtonComponent);
