import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SigIn from '../../models/signin/Signin';
import * as SigninModel from '../../models/signin/Signin';

interface RequestFunctionAction {
    type: 'REQUEST_SIGNIN';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_SIGNIN';
    model: SigIn.ResponseSignIn;
    typeLogin: string
}

interface SigninFail {
    type: 'FAIL_SIGNIN'
    message: string,
    status: number
}

interface ClearSignin {
    type: 'CLEAR_SIGNIN'
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | SigninFail | ClearSignin

export const actionCreators = {
    requestFunction: (method: string, username: string, password: string, type: string, SignInData: SigninModel.SignInData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && type != "" && type != undefined && method == "POST") {
            var body = {
                'username': username,
                'password': password,
                'type': type,
                'social_id': SignInData.social_id,
                'first_name': SignInData.first_name,
                'last_name': SignInData.last_name,
                'email': SignInData.email
            }

            //console.log(JSON.stringify(body))

            fetch(`/v1/citizen/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    //return response.json() as Promise<SigIn.ResponseSignIn>
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            localStorage.setItem('SSO-WEB_LOGIN_TYPE', type);
                            dispatch({ type: 'FAIL_SIGNIN', status: 204, message: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            if (JSON.stringify(data.token) != undefined && JSON.stringify(data.token) != "" && JSON.stringify(data.token) != null) {

                                localStorage.setItem('SSO-WEB_APPLICATION', data.token);
                                localStorage.setItem('SSO-WEB_LOGIN_TYPE', type);

                                dispatch({ type: 'RECEIVE_SIGNIN', model: data, typeLogin: type });
                            } else {
                                dispatch({ type: 'RECEIVE_SIGNIN', model: data, typeLogin: "" });
                            }
                        })
                    }
                })
        }
        else if (appState) {
            dispatch({ type: 'REQUEST_SIGNIN' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_SIGNIN' });
        }
    }

};

const unloadedState: SigIn.SigninState = { isLoadingSignin: false, isLoginSuccess: false, responseSignin: undefined, type: undefined, statusLogin: 0 };

export const reducer: Reducer<SigIn.SigninState> = (state: SigIn.SigninState | undefined, incomingAction: Action): SigIn.SigninState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_SIGNIN':
            console.log('reducer REQUEST_SIGNIN')
            return {
                isLoadingSignin: true,
                isLoginSuccess: false,
                statusLogin: 0
            };
        case 'RECEIVE_SIGNIN':
            console.log('reducer RECEIVE_SIGNIN', action.model)
            return {
                isLoadingSignin: false,
                isLoginSuccess: true,
                responseSignin: action.model,
                type: action.typeLogin,
                statusLogin: 200
            };
        case 'FAIL_SIGNIN':
            console.log('reducer FAIL_SIGNIN')
            return {
                isLoadingSignin: false,
                isLoginSuccess: true,
                messageSingin: action.message,
                statusLogin: 400
            };
        case 'CLEAR_SIGNIN':
            console.log('reducer CLEAR_SIGNIN')
            return {
                isLoadingSignin: false,
                isLoginSuccess: false,
                statusLogin: 0
            };

        default: return state;
    }
};