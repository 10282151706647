import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import * as SignInPoolStore from '../../store/signin/SignInPool';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'
import { SignInData } from '../../models/signin/Signin';
import { showLoading, hideLoading } from '../Common/Common'

type Props = {
    data: SignInData | undefined;
    grandParentLoginObject: any;
    loginSuccessCallback: (token: string, type: string) => void;
    onClickLoginWithAzureAD: () => void;
}

function LoginButtonPoolWithAzureComponent(props: Props) {
    const dispatch = useDispatch();

    const { signInPool } = useSelector<
        Defined<ApplicationState>,
        Defined<
            Pick<
                ApplicationState, 'signInPool'
            >
        >
    >(state => ({
        signInPool: state.signInPool
    }));

    const {
        data,
        grandParentLoginObject,
        loginSuccessCallback,
        onClickLoginWithAzureAD
    } = props

    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState('');

    useEffect(() => {
        const { isLoadingSignin, isLoginSuccess, responseSignin } = signInPool;
        console.log(responseSignin);
        if (!isLoadingSignin) {
            hideLoading();
            if (isLoginSuccess) {
                if (responseSignin?.access_token) {
                    loginSuccessCallback(responseSignin.access_token, type);
                    toggleLoading();
                }
                else {
                    if (responseSignin?.message) {
                        try {
                            var msg = JSON.parse(responseSignin?.message)

                            if (msg?.message === 'REQUIRED_AZURE_LOGIN') {
                                Swal.fire({
                                    title: 'You must be Login with Azure AD',
                                    text: 'คุณต้อง Login ผ่าน Azure AD เท่านั้น',
                                    icon: 'error',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Login With Azure AD'
                                }).then((result) => {
                                    toggleLoading();
                                    if (result.isConfirmed)
                                    {
                                        onClickLoginWithAzureAD();
                                    }
                                })

                                return;
                            }
                        }
                        catch { }
                    }

                    Swal.fire({
                        title: 'Username & Password Invalid!',
                        text: 'Username และ Password ไม่ถูกต้อง',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน!'
                    }).then((result) => {
                        toggleLoading();
                    })
                }

                dispatch(SignInPoolStore.actionCreators.clearFunction());
            }
        }
    }, [signInPool])

    const toggleLoading = useCallback(() => {
        setIsLoading(prevState => !prevState);
    }, [setIsLoading])

    const onSubmit = () => {
        const { username, password } = grandParentLoginObject;
        if (data) {
            const { social_id } = data;

            if (username != "" && password != "") {
                toggleLoading();
                showLoading();
                const loginType = 'azure';
                setType(loginType)
                dispatch(SignInPoolStore.actionCreators.requestFunction(username, password, loginType, social_id));

            } else {
                Swal.fire({
                    title: 'Required Username & Password!',
                    text: 'กรุณากรอก Username และ Password ให้ครบถ้วน',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {
                    if (result.value) {

                    }
                })
            }
        }
        else {
            if (username != "" && password != "") {
                toggleLoading();
                showLoading();
                const loginType = '';
                setType(loginType)
                dispatch(SignInPoolStore.actionCreators.requestFunction(username, password, loginType, ''));

            } else {
                Swal.fire({
                    title: 'Required Username & Password!',
                    text: 'กรุณากรอก Username และ Password ให้ครบถ้วน',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {
                    if (result.value) {

                    }
                })
            }
        }
    }

    return (
        <>
            <button
                type="submit"
                className="btn btn-info btn-block"
                style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }}
                onClick={onSubmit}
                disabled={isLoading}>
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {isLoading && <span>Loading Data from Server</span>}
                {!isLoading && <span><b>Login</b></span>}
            </button>
        </>
    );
    //}
}

export default LoginButtonPoolWithAzureComponent;

//export default connect(
//    (state: ApplicationState) => ({ ...state.signInPool , ...state.Redirect }),
//        ({ ...Stores.actionCreators })
//)(LoginButtonPoolWithAzureComponent);
