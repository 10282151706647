import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, version: string, isHideAlert: boolean, dropdownOpen: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            version: '',
            isHideAlert: false,
            dropdownOpen: false
        };
    }

    componentDidMount() {
        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                }
            )
    }

    private gg() {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Single Sign On Service<span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/addconfig">Call Back</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/citizen/editcitizen" onClick={ () => this.gg()}>Edit Citizen</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/auth/signout">Logout</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
