//import * as React from 'react';
//import { Redirect } from "react-router";
//import { connect } from 'react-redux';
//import { ApplicationState } from '../../store';
//import * as Stores from '../../store/signin/Signin';
//import * as Models from '../../models/signin/Signin';
//import { config } from './azure/Config';
//import { UserAgentApplication } from 'msal';
//import * as EditCitizenModels from '../../models/EditCitizen';
//import * as EditCitizenStores from '../../store/EditCitizen';

//export const userAgentApplication = new UserAgentApplication({
//    auth: {
//        clientId: config.clientId,
//        redirectUri: config.redirectUri,
//        postLogoutRedirectUri: config.postLogoutRedirectUri,
//    },
//    cache: {
//        cacheLocation: "localStorage",
//        storeAuthStateInCookie: true
//    }
//});

//type EditCitizenStore = EditCitizenModels.EditCitizenState & typeof Stores.actionCreators;
//type Store = Models.SigninState & typeof Stores.actionCreators.requestFunction;

//class LogoutAzure extends React.Component<any, Store & EditCitizenStore> {

//    constructor(props: any) {
//        super(props);
//    }

//    public render() {

//        userAgentApplication.logout();

//        return (
//            <>
//                {/*<Redirect to="/auth/signout" />*/}
//            </>
//        )
//    }
//}

//export default connect(
//    (state: ApplicationState) =>
//        ({ ...state.signin, ...state.editCitizen, }),
//    ({ ...EditCitizenStores.actionCreators, ...Stores.actionCreators, }))
//    (LogoutAzure);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useHistory, useLocation } from 'react-router-dom';

import * as AppStore from '../../store/AppState';
import * as SocialModel from '../../models/AppState';

import { UserAgentApplication } from 'msal';

type Props = {
};

function LogoutAzure(props: Props, ref) {
    const { appState, Redirect } = useSelector<
        Defined<ApplicationState>,
        Defined<Pick<ApplicationState, 'appState' | 'Redirect'>>
    >(state => ({
        appState: state.appState,
        Redirect: state.Redirect
    }));

    const [socialAuth, setSocialAuth] = useState<SocialModel.SocialAuth>();

    useEffect(() => {
        const { isLoaded, socialAuth } = appState;
        if (!isLoaded) {
            return;
        }

        setSocialAuth(socialAuth);
    }, [appState]);

    useEffect(() => {
        if (socialAuth &&
            socialAuth.azure_client_id) {
            const userAgentApplication = new UserAgentApplication({
                auth: {
                    clientId: socialAuth?.azure_client_id,
                    redirectUri: socialAuth?.azure_login_redirect_uri,
                    postLogoutRedirectUri: socialAuth?.azure_logout_redirect_uri,
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                }
            });

            userAgentApplication.logout();
        }
    }, [socialAuth]);

    return (
        <>
            {/*<Redirect to="/auth/signout" />*/}
        </>
    )
}

export default React.forwardRef(LogoutAzure);