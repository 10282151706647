export function isOkPass(p) {
    console.log('-----------');
    console.log(p);
    var anUpperCase = /[A-Z]/;
    var aLowerCase = /[a-z]/;
    var aNumber = /[0-9]/;
    var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    var obj: any = {};
    obj.result = false;

    if (p.length < 8) {
        obj.length = false;
        obj.error = 'Not long enough!';
    } else {
        obj.length = true;
    }

    var numUpper = 0;
    var numLower = 0;
    var numNums = 0;
    var numSpecials = 0;
    for (var i = 0; i < p.length; i++) {
        if (anUpperCase.test(p[i])) numUpper++;
        else if (aLowerCase.test(p[i])) numLower++;
        else if (aNumber.test(p[i])) numNums++;
        else if (aSpecial.test(p[i])) numSpecials++;
    }

    if (numUpper < 1) {
        obj.result = false;
        obj.error = 'Wrong Format!';
        obj.numUpper = false;
    } else {
        obj.numUpper = true;
    }

    if (numLower < 1) {
        obj.result = false;
        obj.error = 'Wrong Format!';
        obj.numLower = false;
    } else {
        obj.numLower = true;
    }

    if (numNums < 1) {
        obj.result = false;
        obj.error = 'Wrong Format!';
        obj.numNums = false;
    } else {
        obj.numNums = true;
    }

    if (numSpecials < 1) {
        obj.result = false;
        obj.error = 'Wrong Format!';
        obj.numSpecials = false;
    } else {
        obj.numSpecials = true;
    }

    if (
        obj.numUpper &&
        obj.numLower &&
        obj.numNums &&
        obj.numSpecials &&
        obj.length
    ) {
        obj.result = true;
    }
    return obj;
}

export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function validateEmail(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const showLoading = () => {
    document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
}

export const hideLoading = () => {
    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
}