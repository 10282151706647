import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserAgentApplication } from 'msal';
import Swal from 'sweetalert2';
import axios from 'axios';
import { getUserProfile } from './azure/utils/MSUtils';

import { showLoading, hideLoading } from '../Common/Common';

import * as SignInStore from '../../store/signin/Signin';
import colors from '../../default/Colors';

import AzureMappingUserComponent from '../auth/azure/AzureMappingUserComponent';

import { SignInData } from '../../models/signin/Signin';

type Props = {
    azureInitData: SignInData | undefined,
    loginObject: any;
    loginSuccessCallback: (token: string, type: string) => void;
    onClickLoginWithAzureAD: () => void;
    azureErrorHandle: (message: string) => void;
};

function AzureButtonComponent(props: Props, ref) {
    const dispatch = useDispatch();

    const { signin } = useSelector<
        Defined<ApplicationState>,
        Defined<Pick<ApplicationState, 'signin'>>
    >(state => ({
        signin: state.signin
    }));

    const { loginSuccessCallback, onClickLoginWithAzureAD, azureErrorHandle, azureInitData } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<SignInData | undefined>(undefined);
    const [showLocalLogin, setShowLocalLogin] = useState(false);

    const getUserAgentApplication = () => {
        var { loginObject } = props;

        return new UserAgentApplication({
            auth: {
                authority: loginObject.azure_authority_uri,
                clientId: loginObject.azure_client_id,
                redirectUri: loginObject.azure_login_redirect_uri,
                postLogoutRedirectUri: loginObject.azure_logout_redirect_uri
            },
            cache: {
                //cacheLocation: "sessionStorage", // needed to avoid "login required" error
                cacheLocation: 'localStorage', // needed to avoid "login required" error
                storeAuthStateInCookie: true, // recommended to avoid certain IE/Edge issues
            }
        });
    };

    function isFromMobileBrowser() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const login = async () => {
        var userAgentApplication = getUserAgentApplication();
        var scopes = ['user.read'];
        const isMobile = isFromMobileBrowser();

        try {
            showLoading();

            if (isMobile) {
                await userAgentApplication.loginRedirect({
                    scopes: scopes,
                    prompt: 'select_account'
                });

                return;
            } else {
                //console.log(userAgentApplication)
                await userAgentApplication.loginPopup({
                    scopes: scopes,
                    prompt: 'select_account'
                });

                var account = userAgentApplication.getAccount()
                var azureData = {
                    email: account.userName,
                    social_id: account.accountIdentifier,
                    first_name: account.name,
                    last_name: ''
                };

                //console.log(userAgentApplication)
                setData(azureData);

                return;
            }

            //const user = await getUserProfile(userAgentApplication, scopes);
            //console.log(user)

            //var azureData = {
            //    email: user.mail || user.userPrincipalName,
            //    social_id: user.id,
            //    first_name: user.givenName,
            //    last_name: user.surname
            //};

            //console.log(user)
        } catch (err) {
            setData(undefined);

            hideLoading();

            //console.log((err as Error).message);
            const errMessage = (err as Error).message;

            await azureErrorHandle(errMessage);
            
        } finally {
            hideLoading();
        }
    };

    useEffect(() => {
        //console.log(azureInitData)
        if (azureInitData) {
            setData(azureInitData)
        }
    }, [azureInitData])

    useEffect(() => {
        if (data) {
            //console.log(data)
            dispatch(
                SignInStore.actionCreators.requestFunction(
                    'POST',
                    '',
                    '',
                    'azure',
                    data
                )
            );
        }
    }, [data]);

    const logout = () => {
        var userAgentApplication = getUserAgentApplication();
        userAgentApplication.logout();
    };

    useEffect(() => {
        const {
            isLoadingSignin,
            isLoginSuccess,
            responseSignin,
            type,
            statusLogin,
            messageSingin
        } = signin;
        hideLoading();

        if (isLoginSuccess) {
            if (statusLogin == 200) {
                loginSuccessCallback(responseSignin?.token!, 'azure');
            } else {
                if (messageSingin) {
                    try {
                        var msg = JSON.parse(messageSingin);

                        if (msg.message === 'NEED_MAPPING_USER') {
                            toggleShowLocalLogin();

                            return;
                        } else {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด!',
                                text: msg.message,
                                titleText: 'เข้าสู่ระบบไม่สำเร็จ',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                allowOutsideClick: false
                            });
                        }
                    } catch {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด!',
                            text: messageSingin,
                            titleText: 'เข้าสู่ระบบไม่สำเร็จ',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            allowOutsideClick: false
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด!',
                        text: 'ไม่พบ error message กรุณาติดต่อผู้ดูแลระบบ',
                        titleText: 'เข้าสู่ระบบไม่สำเร็จ',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        allowOutsideClick: false
                    });
                }
            }
        }
    }, [signin]);

    const toggleShowLocalLogin = useCallback(() => {
        setShowLocalLogin(prevState => !prevState);
    }, [setShowLocalLogin]);

    return (
        <>
            <button
                ref={ref}
                type="button"
                onClick={() => login()}
                style={{
                    backgroundColor: colors.btnSSO_Blue,
                    color: '#ffffff'
                }}
                className="mt-3 btn btn-light btn-block"
                disabled={isLoading}
            >
                <img
                    style={{ paddingRight: '15px' }}
                    src={require('../../images/icon/microsoft.png')}
                />
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: '5px' }}
                    />
                )}
                <span>
                    {isLoading ? (
                        'Loading Data from Server'
                    ) : (
                        <b>Login with Azure AD</b>
                    )}
                </span>
            </button>

            <AzureMappingUserComponent
                show={showLocalLogin}
                close={toggleShowLocalLogin}
                data={data}
                loginSuccessCallback={loginSuccessCallback}
                onClickLoginWithAzureAD={onClickLoginWithAzureAD}
            />
        </>
    );
}

export default React.forwardRef(AzureButtonComponent);
//connect(
//    (state: ApplicationState) => ({ ...state.signin, ...state.ForgotPassword }),
//    ({ ...Stores.actionCreators, ...ForgotPasswordStore.actionCreators })
//)(AzureButtonComponent);
