import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useReadLocalStorage } from 'usehooks-ts';

import { Step1, Step2, Step3 } from './AllowOnboarding';

type Props = {
    openOnboardModal?: boolean;
    onClose?: () => void;
};

function AllowConnectOnboardModal(props: Props) {
    const { openOnboardModal, onClose } = props;

    const lineConnectStatus = useReadLocalStorage('line-connect-status');

    const [step, setStep] = useState(1);

    useEffect(() => {
        switch (lineConnectStatus) {
            default:
                return;
            case 'pending':
                setStep(2);
                break;
            case 'done':
                setStep(3);
                break;
        }
    }, [lineConnectStatus]);

    const next = () => {
        if (step === 3) {
            onClose && onClose();
            setTimeout(() => setStep(1), 150);
        }

        if (step < 3) {
            setStep(step + 1);
        }
    };

    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const stepComponents = useRef(
        new Map<number, () => JSX.Element>([
            [1, Step1],
            [2, Step2],
            [3, Step3]
        ])
    ).current;
    const SelectedStep = stepComponents.get(step) ?? (() => null);

    return (
        <Modal
            isOpen={openOnboardModal}
            toggle={onClose}
            size="md"
            className="max-w-[420px]"
            style={{}}
            contentClassName="min-h-[580px] rounded-[32px] overflow-hidden"
            scrollable
        >
            <ModalHeader
                toggle={onClose}
                className="items-center [&_.modal-title]:flex-1"
            >
                <h2 className="flex-1 text-center">Block or allow pop-ups</h2>
            </ModalHeader>
            <ModalBody>
                <SelectedStep />
            </ModalBody>
            <ModalFooter className="flex items-center justify-center">
                <Button
                    size="lg"
                    color="success"
                    className="mx-2 px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={previous}
                >
                    ย้อนกลับ
                </Button>
                <Button
                    size="lg"
                    color="success"
                    className="mx-2 px-4 py-2 bg-green-500 text-white rounded"
                    onClick={next}
                >
                    {step < 3 ? 'ถัดไป' : 'ปิด'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default AllowConnectOnboardModal;
